







































import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";

import axios from "@/libs/axios/axios";
import { AxiosResponse, AxiosError } from "axios";

@Component
export default class ChooseTenant extends Vue {
  @Action("setCurrentTenant", { namespace: "user" })
  setCurrentTenant!: Function;
  @Action("logOff", { namespace: "login" })
  logOff!: Function;
  tenants = [];

  async mounted() {
    const response: void | AxiosResponse = await axios
      .get("available-tenants")
      .catch((error: AxiosError) => {
        console.log(error);
      });

    if (response && response.data) {
      this.tenants = response.data.tenants;
    }
    if (this.tenants.length === 1) {
      this.setCurrentTenant({ tenant: this.tenants[0], tenants: this.tenants });
    } else if (this.tenants.length <= 0) {
      alert("Geen clienten gevonden.");
      this.logOff();
    }
  }
}
